


<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">钱包充值明细</div>
      <div class="formDiv" style="width: 80vw; margin-left: 8vw">
        <el-form label-position="right" label-width="60px">
          <el-row :gutter="10">
            <el-col :span="4">
              <el-form-item label="手机号:">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入手机号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="车型:">
                <el-select
                  v-model="FormData.type_id"
                  placeholder="请选择车型"
                  clearable
                >
                  <el-option
                    v-for="car in carList"
                    :key="car.value"
                    :value="car.value"
                    :label="car.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-show="isadmin" :span="4">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="时间:" class="time_pick">
                <el-date-picker
                  style="height: 40px; width: 100%"
                  v-model="FormData.orderTime"
                  type="daterange"
                  unlink-panels
                  range-separator=" - "
                  start-placeholder="请选择开始时间"
                  end-placeholder="请选择结束时间"
                  size="small"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  clearable
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div
                class="BtnSearch"
                @click="searchBtn()"
              >
                查询
              </div>
            </el-col>
            <el-col :span="1" style="margin-left:20px">
              <Data2Excel api='/report/user_charge_detail' :data="dataa" fileName="用户充值明细"></Data2Excel>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="510px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="用户编号" prop="user_number" />
          <el-table-column label="用户手机号" prop="phone" />
          <el-table-column label="充值车型" prop="type_id">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电动车</span>
              <span v-if="scope.row.type_id == 4">电动汽车</span>
            </template>
          </el-table-column>
          <el-table-column
            label="充值前余额(元)"
            prop="before_charge_amount"
            :formatter="rounding"
          />
          <el-table-column
            label="充值金额(元)"
            prop="charge_amount"
            :formatter="rounding"
          />
          <el-table-column
            label="充值后余额(元)"
            prop="after_charge_amount"
            :formatter="rounding"
          />
          <el-table-column
            label="充值时间"
            prop="create_time"
            :formatter="formatDate"
          />
          <el-table-column label="运营商" prop="username" />
          <el-table-column label="操作" width="110" align="center">
            <template #default="scope">
              <!-- <el-button round color='#42a7a9' plain size="mini" @click="charge(scope.$index, scope.row)">充值</el-button> -->
              <el-button
                round
                type="warning"
                plain
                size="mini"
                @click="refund(scope.$index, scope.row)"
                >退费</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <div class="flex1">
          <p>
            充值总次数：<span>{{
              tableData1.count_top_up ? tableData1.count_top_up : 0
            }}</span>
          </p>
          <p>
            充值总金额(元)：<span>{{
              tableData1.sum_top_up ? tableData1.sum_top_up : "0.00"
            }}</span>
          </p>
          <p>
            当前账户余额(元)：<span>{{ wallet_balance ? wallet_balance : "0.00" }}</span>
          </p>
        </div>
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :small="small"
          layout="total, slot, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
          <template #default>
            <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
          </template>
        </el-pagination>
      </div>
      <el-dialog
        v-model="Dialog1.editLevy"
        title="钱包退费"
        width="900px"
        :before-close="closeDialog"
        class="dialog"
      >
        <div class="formDiv">
          <el-form label-position="right" label-width="100px">
            <el-row :gutter="20">
              <el-col :span="1"></el-col>
              <el-col :span="9">
                <el-form-item label="用户手机号:">
                  <el-input
                    style="width: 100%"
                    v-model="Dialog1.DialogData.phone"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="9">
                <el-form-item label="退费原因:">
                  <el-input
                    v-model="Dialog1.DialogData.refund_reason"
                    placeholder="请输入退费原因"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="18">
              <el-col :span="1"></el-col>
              <!-- <el-col :span="3"></el-col> -->
              <el-col :span="9">
                <el-form-item label="退费金额(元):">
                  <el-select
                    style="width: 100%"
                    v-model="Dialog1.DialogData.money"
                    placeholder="请选择退款金额"
                    @change="handlemoney"
                  >
                    <el-option
                      v-for="moneys in moneyList"
                      :key="moneys.value"
                      :value="moneys.value"
                      :label="moneys.label"
                    />
                    <!-- </el-option> -->
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="9" v-show="isshow">
                <el-form-item label="退费金额(元):">
                  <el-input
                    type="number"
                    v-model="Dialog1.DialogData.refund_amount"
                    placeholder="请输入退费金额(元)"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button class="Btn" @click="closeDialog">取消</el-button>
            <el-button class="Btn" type="primary" @click="submitDialog1"
              >提交</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>

    <!-- </div> -->
    <!-- 退费弹窗 -->
    <!-- <div v-dialogdrag> -->
  </div>
  <!-- </div> -->
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { user_charge_detail, operator_data, re_money } from "@/request/api";
import { ElMessage } from "element-plus";
import Data2Excel from "@/component/Data2Excel.vue";
import ProvinceCityCountry from "../../assets/json/address";
export default {
  name: "InvestDetail",
  components:{
    Data2Excel
  },
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        orderTime: ["", ""],
      },
      isshow: false,
      isadmin: false,
      users: [],
      tableData: [],
      tableData1: {},
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      moneyList: [
        {
          value: 0,
          label: "全额退费",
        },
        {
          value: 1,
          label: "手动输入",
        },
      ],
      values: "",
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
      moneyrefund: "",
      loading: false,
      provinceData: ProvinceCityCountry.address,
      Dialog1: {
        editLevy: false,
        DialogData: {},
      },
      wallet_balance: 0.0,
      dataa:{}
    });
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const closeDialog = () => {
      data.Dialog1.editLevy = false;
      data.Dialog1.editLevy = false;
    };
    const submitDialog1 = () => {
      console.log("提交");
      submitData1();
    };
    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let now = new Date(data);
      let year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      let month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      let hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      let minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
      let seconds =
        now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); //得到秒数

      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        seconds
      );
    };
    const searchBtn = () => {
      // data.currentPage = 1;
      getList();
    };
    const handlemoney = (value) => {
      data.values = value;
      if (data.values == 0) {
        data.isshow = false;
        // data.refunds.refund = data.refunds.total
      } else if (data.values == 1) {
        data.isshow = true;
      }
    };
    // const getTime = () => {
    //     let date = new Date;
    //     let y = date.getFullYear().toString();
    //     let m = (date.getMonth() + 1).toString().padStart(2, 0);
    //     var curretMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    //     var curretMonthDayCount = curretMonth.getDate();
    //     data.FormData.orderTime[0] = [y, m, 1].join("-")
    //     data.FormData.orderTime[1] = [y, m, curretMonthDayCount].join("-")

    //   }
    const getList = () => {
      data.wallet_balance = 0.0;
      let stDate;
      let endDate;
      if (data.FormData.orderTime) {
        stDate = data.FormData.orderTime[0] ? data.FormData.orderTime[0] : "";
        endDate = data.FormData.orderTime[1] ? data.FormData.orderTime[1] : "";
      } else if (
        data.FormData.orderTime === null ||
        data.FormData.orderTime === undefined ||
        data.FormData.orderTime === ""
      ) {
        stDate = "";
        endDate = "";
      }
      data.dataa = {
        username: data.FormData.username,
        operator_id: sessionStorage.getItem("userID"),
        phone: data.FormData.phone,
        type_id: data.FormData.type_id,
        start_execute_date: stDate,
        stop_execute_date: endDate,
        page: data.currentPage,
      };
      data.loading = true;
      user_charge_detail(data.dataa).then((res) => {
        if (res) {
          console.log(res, "充值详情列表");
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.count_data;
            data.tableData1.count_top_up = 
              res.count_top_up;
            data.tableData1.sum_top_up = Number(res.sum_top_up)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            data.wallet_balance = Number(res.wallet_balance.achieve)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            data.tableData.forEach((a)=>{
              a.before_charge_amount = Number(a.before_charge_amount )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              a.charge_amount = Number(a.charge_amount )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              a.after_charge_amount = Number(a.after_charge_amount )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            })
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };

    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };
    const refund = (index, row) => {
      data.Dialog1.editLevy = true;
      data.Dialog1.DialogData = row;
    };
    const submitData1 = () => {
      if (data.values == 0) {
        data.moneyrefund = data.Dialog1.DialogData.charge_amount;
      } else if (data.values == 1) {
        data.moneyrefund = data.Dialog1.DialogData.refund_amount;
      }
      const dataa = {
        refund: data.moneyrefund.toString(),
        out_trade_no: data.Dialog1.DialogData.out_trade_no.toString(),
        transaction_id: data.Dialog1.DialogData.transaction_id.toString(),
        total: data.Dialog1.DialogData.charge_amount.toString(),
        refund_reason: data.Dialog1.DialogData.refund_reason.toString(),
      };
      console.log(dataa, "dataadadad");
      re_money(dataa).then((res) => {
        console.log(res);
        if (res) {
          closeDialog();
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              message: "退费成功",
              type: "success",
            });
            getList();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("提交失败");
        }
      });
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList();
    };
    onMounted(() => {
      getUsername();
      // getTime()
      getList();
    });
    return {
      ...toRefs(data),
      formatDate,
      searchBtn,
      // getTime,
      getList,
      closeDialog,
      getUsername,
      submitDialog1,
      refund,
      handlemoney,
      rounding,
      handleCurrentChange,
    };
  },
};
</script>
<style scoped>
.bottomDiv {
  height: 100%;
}
.my-label {
  background: var(--el-color-success-light-9);
}
.my-content {
  background: var(--el-color-danger-light-9);
  width: 100%;
}
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}
.flex1 {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  /* margin-top: -10px; */
}
.flex1 p {
  font-size: 14px;
  margin-right: 15px;
}
.flex1 span {
  color: red;
}
</style>